






















import { Vue } from "vue-property-decorator";
import { Component } from "vue-property-decorator";

@Component({})
export default class Maintenance extends Vue {}
